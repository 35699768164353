<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2" />
      <Steps
        :levels="level"
        v-if="level && appStatus == 1"
        :hierarchy="hierarchy"
        :showZb="details.showZb"
      />
      <div class="content-show">
        <van-tabs
          v-model="active"
          :animated="true"
          :class="appStatus == 1 ? 'disappear' : ''"
        >
          <van-tab title="详情页" name="a" class="">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="变更基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="招标编号" :value="details.fileCode" />
                    <van-cell title="招标物资" :value="details.goods" />
                    <van-cell title="包号" :value="details.packCode" />
                    <van-cell title="附件">
                      <div
                        v-for="(item, index) in details.bidAttachmentVos"
                        :key="index"
                        class="cell"
                      >
                        <a
                          v-if="item.suffix != 'rar' && item.suffix != 'zip'"
                          :href="
                            '/api-ec/fileDownload/FileuploadAction/' +
                              item.id +
                              '-' +
                              item.title
                          "
                          >{{ item.title }}</a
                        >
                        <span v-else @click="onCompression">{{
                          item.title
                        }}</span>
                      </div>
                    </van-cell>
                    <van-cell title="变更原因" :value="details.remark">
                    </van-cell>
                    <van-cell
                      title="是否实质性变更"
                      :value="details.materialchangeName"
                    />
                    <van-cell
                      title="变更类型"
                      :value="details.bidChangeTypeName"
                      v-if="
                        details.bidChangeType == '300001300' ||
                          details.bidChangeType == '300001310'
                      "
                    />
                    <van-cell
                      title="变更类型"
                      :value="details.otherChangeTypeName"
                      v-else
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="开标时间"
                  name="4"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && modify"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="details.dataModDetailVoBefore.opentime"
                    />
                    <van-cell
                      title="变更后"
                      :value="details.dataModDetailVoAfter.opentime"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="售卖时间修改"
                  name="12"
                  icon="label"
                  v-if="details.bidChangeType == '300001300'"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="延期原因"
                      :value="details.dataModSaleendDateVo.delayReasonName"
                    >
                    </van-cell>
                    <van-cell
                      title="同步请求修改开标时间"
                      :value="
                        details.dataModSaleendDateVo.iseditOpentime == 1
                          ? '是'
                          : '否'
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="售卖现状"
                  name="2"
                  icon="label"
                  v-if="
                    details.bidChangeType == '300001300' &&
                      details.dataModSaleendPresentVos != null
                  "
                >
                  <van-cell-group class="group">
                    <van-cell class="quo">
                      <van-row>
                        <van-col span="20">售卖现状</van-col>
                        <van-col span="4">状态</van-col>
                      </van-row>
                    </van-cell>
                    <van-cell
                      class="quo"
                      v-for="(item, index) in details.dataModSaleendPresentVos"
                      :key="index"
                    >
                      <van-row>
                        <van-col span="20">{{
                          item.saleSituationTypeName
                        }}</van-col>
                        <van-col span="4">{{
                          item.saleSituationResult == 1 ? "是" : "否"
                        }}</van-col>
                      </van-row>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="售卖截止时间"
                  name="3"
                  icon="label"
                  v-if="details.bidChangeType == '300001300'"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="details.dataModSaleendDateVo.saleenddateBefore"
                    />
                    <van-cell
                      title="变更后"
                      :value="details.dataModSaleendDateVo.saleenddateAfter"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="开标时间"
                  name="4"
                  icon="label"
                  v-if="details.bidChangeType == '300001300'"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="details.dataModSaleendDateVo.opentimeBefore"
                    />
                    <van-cell
                      title="变更后"
                      :value="details.dataModSaleendDateVo.opentimeAfter"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="估算金额"
                  name="5"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && estimate"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="details.dataModDetailVoBefore.subcontractamount"
                    />
                    <van-cell
                      title="变更后"
                      :value="details.dataModDetailVoAfter.subcontractamount"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="代理商"
                  name="6"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && circulation"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="
                        details.dataModDetailVoBefore.acceptagent == 1
                          ? '接受'
                          : '不接受'
                      "
                    />
                    <van-cell
                      title="变更后"
                      :value="
                        details.dataModDetailVoAfter.acceptagent == 1
                          ? '接受'
                          : '不接受'
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="流通商"
                  name="7"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && circulation"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="
                        details.dataModDetailVoBefore.acceptdistribution == 1
                          ? '接受'
                          : '不接受'
                      "
                    />
                    <van-cell
                      title="变更后"
                      :value="
                        details.dataModDetailVoAfter.acceptdistribution == 1
                          ? '接受'
                          : '不接受'
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="资格审查条件"
                  name="8"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && qualification"
                >
                  <van-cell-group class="group">
                    <van-cell class="quo">
                      <van-row>
                        <van-col span="4">序号</van-col>
                        <van-col span="16">资格审查条件</van-col>
                        <van-col span="4">类型</van-col>
                      </van-row>
                    </van-cell>
                    <van-cell
                      class="quo"
                      v-for="(item, index) in details.dataModBidquotaVos"
                      :key="index"
                    >
                      <van-row>
                        <van-col span="4">{{ index + 1 }}</van-col>
                        <van-col span="16" class="heitext">{{
                          item.zbname
                        }}</van-col>
                        <van-col span="4">{{
                          item.flag == 1
                            ? "新增"
                            : item.flag == 2
                            ? "修改"
                            : "正常"
                        }}</van-col>
                      </van-row>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="商务分计算方法"
                  name="9"
                  icon="label"
                  v-if="details.bidChangeType == '300001330' && business"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="
                        details.dataModDetailVoBefore.gradecommerce == 1
                          ? '最低价法'
                          : details.dataModDetailVoBefore.gradecommerce == 2
                          ? '基准价法'
                          : details.dataModDetailVoBefore.gradecommerce == 3
                          ? '手工录入'
                          : ''
                      "
                    />
                    <van-cell
                      title="变更后"
                      :value="
                        details.dataModDetailVoAfter.gradecommerce == 1
                          ? '最低价法'
                          : details.dataModDetailVoAfter.gradecommerce == 2
                          ? '基准价法'
                          : details.dataModDetailVoAfter.gradecommerce == 3
                          ? '手工录入'
                          : ''
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="评标办法"
                  name="10"
                  icon="label"
                  v-if="
                    details.bidChangeType == '300001330' &&
                      business &&
                      details.dataModBidmethodVoBefore
                  "
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="变更前"
                      :value="
                        details.dataModBidmethodVoBefore == null
                          ? ''
                          : details.dataModBidmethodVoBefore.methodname
                      "
                    />
                    <van-cell
                      title="变更后"
                      :value="
                        details.dataModBidmethodVoAfter == null
                          ? ''
                          : details.dataModBidmethodVoAfter.methodname
                      "
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="技术商务比例"
                  name="10"
                  icon="label"
                  v-if="
                    details.bidChangeType == '300001330' &&
                      business &&
                      details.dataModDetailVoBefore.jsb
                  "
                >
                  <van-cell-group class="group">
                    <van-cell title="变更前">
                      <span v-if="details.dataModDetailVoBefore.jsb"
                        >技术{{ details.dataModDetailVoBefore.jsbz }}:商务{{
                          details.dataModDetailVoBefore.swbz
                        }}</span
                      >
                    </van-cell>
                    <van-cell title="变更后">
                      <span v-if="details.dataModDetailVoAfter.jsb"
                        >技术{{ details.dataModDetailVoAfter.jsbz }}:商务{{
                          details.dataModDetailVoAfter.swbz
                        }}</span
                      >
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>

                <van-collapse-item
                  title="物料信息修改"
                  name="11"
                  icon="label"
                  v-if="details.bidChangeType == '300001310'"
                  class="unique"
                >
                  <div
                    class="supplies"
                    v-for="(item, index) in details.dataModBidpackorderVos"
                    :key="index"
                  >
                    <div class="supplies-title">{{ item.prodname }}</div>
                    <div class="supplies-text">
                      物资编码: {{ item.prodcode }}
                    </div>
                    <div class="supplies-text">
                      采购申请编号: {{ item.ordercode }}
                    </div>
                    <div class="supplies-text">数量: {{ item.amount }}</div>
                    <div class="supplies-text">
                      数据状态:
                      {{
                        item.flag == 1
                          ? "新增"
                          : item.flag == 2
                          ? "删除"
                          : "正常"
                      }}
                    </div>
                    <div class="supplies-text">变更状态: 变更</div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="record"
                v-for="(item, index) in details.wfapproveinfoVOs"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.endDateTimeStr"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      submitflag: false,
      show: true,
      loadingfalg: true,
      hierarchy: "",
      arr: [],
      modify: false, //开标时间修改
      estimate: false, //估算概算
      circulation: false, //流通
      qualification: false, //资格审查
      business: false, //商务
      other: false, //其他
      showage: true,
      title: "招标变更审批详情",
      level: null,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      isLoading: false,
      active: "a",
      activeNames: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      details: [],
      numberrows: "2",
      projectlist: [],
      basicTop: {
        data: [],
        columns: [
          {
            prop: "goods",
            label: "招标物资",
          },
          {
            prop: "packCode",
            label: "修改包段",
          },
          {
            prop: "bidChangeTypeName",
            label: "变更类型",
          },
          {
            prop: "remark",
            label: "变更原因",
          },
        ],
      },
    };
  },
  computed: {
    dataModId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  created() {
    this.toDataModInfo();
  },
  methods: {
    async toDataModInfo() {
      const params = {
        dataModId: this.dataModId,
      };
      let data = await this.$api.ec.toDataModInfo(params);
      if (data) {
        this.loadingfalg = false;
      }
      this.level = Number(data.levels);
      this.hierarchy = Number(data.wfCounts);
      this.details = data;
      if (this.details.bidAttachmentVos) {
        this.details.bidAttachmentVos.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1);
        });
      }
      this.basicTop.data = data;
      if (data.bidChangeType == "300001330") {
        this.arr = data.otherChangeType.split(",");
        this.arr.map((item) => {
          if (item == "300001400") {
            this.modify = true;
          } else if (item == "300001410") {
            this.estimate = true;
          } else if (item == "300001420") {
            this.circulation = true;
          } else if (item == "300001430") {
            this.qualification = true;
          } else if (item == "300001440") {
            this.business = true;
          } else if (item == "300001460") {
            this.other = true;
          }
        });
      }
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecbtenderchange",
        });
      } else {
        this.showage = true;
        this.title = "招标变更审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          id: this.dataModId,
          result: 0,
          approvalOpinion: message,
        };
      } else {
        params = {
          id: this.dataModId,
          result: 1,
          approvalOpinion: "同意",
        };
      }
      let data = await this.$api.ec.doDataModApp(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecbtenderchange",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    ondown(item) {
      let query = {
        id: item.id,
        type: 2,
      };
      this.$router.push({ name: "preview", query });
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
/deep/.quo {
  .van-cell__title {
    text-align: center;
  }
  .van-cell__value {
    color: #969799;
    padding-right: 5px;
    .van-row {
      text-align: center;
      .heitext {
        text-align: left;
      }
    }
  }
}
</style>
